import {z} from 'zod'
import {NodeTypeEnum} from '../NodeTypeEnum'

export const CategorySchema = z.enum([
  '',
  'points',
  'registration',
  'loginActivity',
  'storeDemography',
  'progmotion',
  'order',
  'target',
  'campaign',
])

export type CategoryType = z.infer<typeof CategorySchema>

// Days remaining until point expiry date
export const pointsCategorySchema = z.enum(['daysRemainingUntilPointExpiryDate'])

// Registered, Registration Status, registeredDate, Days Since Registration
export const registrationCategorySchema = z.enum([
  'registeredStoreYesNo',
  'storeStatusName',
  'registeredDate',
  'daysSinceRegistration',
])

// 	Days since last login, Active in the current month, Active in the last 3 months
export const loginActivityCategorySchema = z.enum(['daysSinceLastLogin', 'activeCurrentMonthYesNo', 'activeL3M'])

// Store type, Store ID, state name, Territary, City, District, Sales office Code, Sales Channel Code, Division Code, Assigned Segments
export const storeDemographyCategorySchema = z.enum([
  'portalTypeValue',
  'storeId',
  'state',
  'territory',
  'city',
  'district',
  'salesOfficeCode',
  'salesChannelCode',
  'divisionCode',
  'assignedSegments',
])

// Promotion Start Date, Promotion End Date, Promotion ID, Days Since Promotion Start
export const promotionCategorySchema = z.enum([
  'promotionStartDate',
  'promotionEndDate',
  'promotionId',
  'daysSincePromotionStart',
  'displayOrder',
])

// Days Since Last Order, Days Since Last Delivery, Days Until Next Delivery, Days Until Next Order, Next Order Date
export const orderCategorySchema = z.enum([
  'daysSinceLastOrderDate',
  'daysSinceLastDeliveryDate',
  'daysUntilNextDeliveryDate',
  'daysUntilNextOrderDate',
  'nextOrderDate',
])

// Active Start Date, Active End Date, Target Type, Target Type Description, Goal Status, Target Name, Target Hit, Target Hit Date, Target Half period Notification Date, Target Active Flag, 'noOfDaysSinceTargetActiveStartDate'
export const targetCategorySchema = z.enum([
  'activeStartDate',
  'activeEndDate',
  'targetType',
  'targetTypeDescription',
  'goalStatus',
  'targetName',
  'targetHit',
  'targetHitDate',
  'targetHalfPeriodNotificationDate',
  'targetActiveFlag',
  'noOfDaysSinceTargetActiveStartDate',
])

// JoinedCampaignFlag
export const compaignCategorySchema = z.enum(['joinedCampaignFlag'])

const categoryTraitsMappingSchema = z.object({
  points: z.array(pointsCategorySchema),
  registration: z.array(registrationCategorySchema),
  loginActivity: z.array(loginActivityCategorySchema),
  storeDemography: z.array(storeDemographyCategorySchema),
  progmotion: z.array(promotionCategorySchema),
  order: z.array(orderCategorySchema),
  target: z.array(targetCategorySchema),
  campaign: z.array(compaignCategorySchema),
})

export type Category = z.infer<typeof CategorySchema>

export type CategoryTraitsMapping = z.infer<typeof categoryTraitsMappingSchema>

export type NodeTypeForParamsFiltersEnum = NodeTypeEnum.entryCondition | NodeTypeEnum.variant

type ParamConditional = 'AND' | 'OR' | null

export const BooleanOperatorsSchema = z.enum(['isYes', 'isNo'])
export const ComparativeOperatorsSchema = z.enum(['equalTo', 'notEqualTo'])
export const IntegerOperatorsSchema = z.enum([
  'lessThan',
  'lessOrEqual',
  'greaterOrEqual',
  'greaterThan',
  ...ComparativeOperatorsSchema.options,
])
export const ListOperatorsSchema = z.enum(['in', 'notIn'])
export const StringOperatorsSchema = z.enum([...ComparativeOperatorsSchema.options, ...ListOperatorsSchema.options])
export const DateOperatorsSchema = IntegerOperatorsSchema
export const EnumOperatorsSchema = z.enum([...ComparativeOperatorsSchema.options, ...ListOperatorsSchema.options])

export const OperatorsSchema = z.enum([
  ...BooleanOperatorsSchema.options,
  ...IntegerOperatorsSchema.options,
  ...StringOperatorsSchema.options,
  ...DateOperatorsSchema.options,
  ...EnumOperatorsSchema.options,
])
export type Operators = z.infer<typeof OperatorsSchema>

export const BooleanParamsSchema = z.enum([
  'activeCurrentMonthYesNo',
  'activeL3M',
  'registeredStoreYesNo',
  'targetHit',
  'joinedCampaignFlag',
  'targetActiveFlag',
])
export const IntegerParamsSchema = z.enum([
  'daysRemainingUntilPointExpiryDate',
  'daysSinceLastLogin',
  'daysSinceRegistration',
  'daysSinceLastDeliveryDate',
  'daysUntilNextDeliveryDate',
  'daysSinceLastOrderDate',
  'daysUntilNextOrderDate',
  'daysSincePromotionStart',
  'noOfDaysSinceTargetActiveStartDate',
  'displayOrder',
])
export const StringParamsSchema = z.enum([
  'storeId',
  'district',
  'state',
  'territory',
  'city',
  'salesOfficeCode',
  'salesChannelCode',
  'divisionCode',
  'assignedSegments',
  'promotionId',
  'targetName',
  'goalStatus',
  'targetType',
  'targetTypeDescription',
])
export const DateParamsSchema = z.enum([
  'registeredDate',
  'nextOrderDate',
  'promotionStartDate',
  'promotionEndDate',
  'activeStartDate',
  'targetHitDate',
  'activeEndDate',
  'targetHalfPeriodNotificationDate',
])
export const EnumParamsSchema = z.enum(['portalTypeValue', 'storeStatusName'])

export type BooleanParams = z.infer<typeof BooleanParamsSchema>
export type IntegerParams = z.infer<typeof IntegerParamsSchema>
export type StringParams = z.infer<typeof StringParamsSchema>
export type DateParams = z.infer<typeof DateParamsSchema>
export type EnumParams = z.infer<typeof EnumParamsSchema>

export const DataParamsSchema = z.union([
  BooleanParamsSchema,
  IntegerParamsSchema,
  StringParamsSchema,
  DateParamsSchema,
  EnumParamsSchema,
])

type TypeParams = BooleanParams | IntegerParams | StringParams | DateParams | EnumParams
export type DataParams = z.infer<typeof DataParamsSchema> extends TypeParams ? TypeParams : never

export const OperatorTypeSchema = z.enum(['boolean', 'integer', 'string', 'date', 'enum'])
export type OperatorsType = z.infer<typeof OperatorTypeSchema>

export type ParamsSelector = {
  id: number
  conditional: ParamConditional
} & (
  | {
      selectedCategory: CategoryType
      selectedParam: ''
      selectedParamType: ''
      selectedParamData: {
        operator: Operators
        value: null
      }
    }
  | {
      selectedCategory: CategoryType
      selectedParam: IntegerParams
      selectedParamType: typeof OperatorTypeSchema.Values.integer
      selectedParamData: {
        operator: z.infer<typeof IntegerOperatorsSchema>
        value: string | null
      }
    }
  | {
      selectedCategory: CategoryType
      selectedParam: BooleanParams
      selectedParamType: typeof OperatorTypeSchema.Values.boolean
      selectedParamData: {
        operator: z.infer<typeof BooleanOperatorsSchema>
        value: null
      }
    }
  | {
      selectedCategory: CategoryType
      selectedParam: StringParams
      selectedParamType: typeof OperatorTypeSchema.Values.string
      selectedParamData: {
        operator: z.infer<typeof StringOperatorsSchema>
        value: string | null
      }
    }
  | {
      selectedCategory: CategoryType
      selectedParam: DateParams
      selectedParamType: typeof OperatorTypeSchema.Values.date
      selectedParamData: {
        operator: z.infer<typeof DateOperatorsSchema>
        value: string | null
      }
    }
  | {
      selectedCategory: CategoryType
      selectedParam: EnumParams
      selectedParamType: typeof OperatorTypeSchema.Values.enum
      selectedParamData: {
        operator: z.infer<typeof EnumOperatorsSchema>
        value: string | null
      }
    }
)

export type ParamFilters = {
  id: string
  type: NodeTypeForParamsFiltersEnum
  reentryDays: number | null
  haveWarnings?: boolean
  paramsSelectors: Array<ParamsSelector>
}
